import { Component, Input, ViewChild, ViewEncapsulation, inject, output } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { combineLatest, Subject } from 'rxjs';
import { DokaService } from '../../services/doka.service';
import { type UploadedImageData } from '../../models/user';
import { LocalStorageService } from '../../services/persistance.service';

import { FocusableDirective } from '@core/directives/focusable.directive';
import { TranslateModule } from '@ngx-translate/core';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FilePondModule } from 'ngx-filepond';

@Component({
  selector: 'image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FilePondModule, FocusableDirective, TranslateModule, AssetSrcDirective],
})
export class ImageUploaderComponent {
  private readonly dokaService = inject(DokaService);
  private readonly lsService = inject(LocalStorageService);
  @ViewChild('filePond') filePond: any;
  uploadedImage = output<UploadedImageData>();
  uploadStarted = output<File>();
  uploadAborted = output<null>();
  @Input() placeholderText: string;
  @Input() placeholderText2: string;

  public isEmpty = true;
  public isLoading = false;

  public imageCrop: string;
  private imageFullSize: string;
  private fileName: string;
  private dokaConfig: any;

  private uploadedImgId: string;
  private uploadedCropImgId: string;
  private readonly cropUploaded$ = new Subject<string>();
  private readonly imageUploaded$ = new Subject<string>();

  public onFilepondLoad(): void {}

  public getPondOptions(): any {
    const tokenData = this.lsService.getAuthData();
    return {
      multiple: false,
      labelIdle: '',
      acceptedFileTypes: 'image/jpeg, image/png',
      styleButtonRemoveItemPosition: 'left',
      styleLoadIndicatorPosition: 'left',
      instantUpload: false,
      server: {
        url: `${ConfigService.settings.apiUrl}/api/tempFiles`,
        process: {
          onload: (res: any) => {
            const data = JSON.parse(res);
            const imageId = data.fileName;
            this.uploadedImgId = imageId;
            this.imageUploaded$.next(imageId);
          },
          onerror: (err: any) => {
            this.isLoading = true;
            console.log(err);
          },
          headers: {
            Authorization: 'Bearer ' + tokenData.token,
          },
        },
        revert: (uniqueFileId, load, error) => {
          Promise.all([
            fetch(`${ConfigService.settings.apiUrl}/api/tempFiles`, {
              method: 'DELETE',
              body: this.uploadedImgId,
              headers: {
                Authorization: 'Bearer ' + tokenData.token,
              },
            }),
            fetch(`${ConfigService.settings.apiUrl}/api/tempFiles`, {
              method: 'DELETE',
              body: this.uploadedCropImgId,
              headers: {
                Authorization: 'Bearer ' + tokenData.token,
              },
            }),
          ])
            .then(() => load())
            .catch((err) => error(err));
        },
      },
    };
  }

  public onOverlayClick(): void {
    if (this.isEmpty) {
      this.filePond.browse();
    }
  }

  public handleAddFileStart(event: any): void {
    if (event.error) {
      return;
    }

    this.isLoading = true;
    this.isEmpty = false;
  }

  public startFileUpload(file: File): void {
    this.filePond.addFile(file);
  }

  public handleAddFile(event: { file: any; error?: any }): void {
    if (event.error) {
      this.isLoading = false;
      this.isEmpty = true;
      return;
    }

    this.imageFullSize = event.file.getFileEncodeDataURL();
    this.fileName = event.file.filename;

    this.dokaService
      .openDoka(this.imageFullSize, this.dokaConfig, event.file.filename)
      .then((res) => {
        this.imageCrop = res.base64;
        this.dokaConfig = res.dokaConfig;

        const subscription = combineLatest([this.cropUploaded$, this.imageUploaded$]).subscribe((ids) => {
          this.uploadedImage.emit({
            logoImageId: ids[0],
            fullSizeLogoImageId: ids[1],
            logoSettings: JSON.stringify(this.dokaConfig),
          });
          this.isLoading = false;

          setTimeout(() => {
            subscription.unsubscribe();
          }, 0);
        });

        this.uploadStarted.emit(res.file);
        this.filePond.processFiles();
        this.uploadCropImage(res.file);
      })
      .catch(() => {
        this.handleRemoveFile();
      });
  }

  public handleUploadAbort(): void {
    this.uploadAborted.emit(null);
    this.handleRemoveFile();
  }

  public handleRemoveFile(): void {
    this.imageCrop = '';
    this.imageFullSize = '';
    this.dokaConfig = null;
    this.uploadedImage.emit(null);
    this.filePond.removeFiles();
    this.isEmpty = true;
  }

  public editImage(): void {
    this.isLoading = true;
    this.dokaService
      .openDoka(this.imageFullSize, this.dokaConfig, this.fileName)
      .then((res) => {
        this.imageCrop = res.base64;
        this.dokaConfig = res.dokaConfig;
        const subscription = this.cropUploaded$.subscribe((imgId) => {
          this.uploadedImage.emit({
            logoImageId: imgId,
            fullSizeLogoImageId: this.uploadedImgId,
            logoSettings: JSON.stringify(this.dokaConfig),
          });
          this.isLoading = false;

          setTimeout(() => {
            subscription.unsubscribe();
          }, 0);
        });

        this.uploadCropImage(res.file);
      })
      .catch(() => (this.isLoading = false));
  }

  public changeImage(): void {
    this.filePond.browse();
  }

  private uploadCropImage(file: File): void {
    this.dokaService.uploadImage(file).then((res) => {
      const imageId = res.fileName;
      this.uploadedCropImgId = imageId;
      this.cropUploaded$.next(imageId);
    });
  }
}
