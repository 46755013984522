import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '@core/services/config.service';
import { ModalService } from '@core/services/modal.service';
import { ToastrService } from '@core/services/toastr.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FlatfileService } from '@core/services/flatfile/flatfile.service';
import { SignalrService } from '@core/services/signalr.service';
import { of, Subject, tap } from 'rxjs';
import { productImportWorkbook } from '@core/services/flatfile/product-import-workbook';
import { catchError, filter, finalize, switchMap, take, takeUntil } from 'rxjs/operators';
import { ImportErrorsModalComponent } from '@clover/products/modals/import-errors-modal/import-errors-modal.component';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { ToggleComponent } from '@design/forms/toggle/toggle.component';
import { FormErrorMessagesComponent } from '@core/components/form-error-messages/form-error-messages.component';

@UntilDestroy()
@Component({
  selector: 'app-import-products-modal',
  templateUrl: './import-products-modal.component.html',
  styleUrls: ['./import-products-modal.component.scss'],
  standalone: true,
  imports: [
    FocusableDirective,
    TranslateModule,
    NgOptimizedImage,
    ToggleComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormErrorMessagesComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportProductsModalComponent {
  form = new FormGroup({
    worklistName: new FormControl<string>(
      {
        value: '',
        disabled: true,
      },
      [Validators.required],
    ),
  });

  loading = false;
  cancel$: Subject<void>;

  protected readonly activeModal = inject(NgbActiveModal);

  private readonly modalService = inject(ModalService);
  private readonly flatfileService = inject(FlatfileService);
  private readonly signalRService = inject(SignalrService);
  private readonly toastr = inject(ToastrService);
  private readonly cdr = inject(ChangeDetectorRef);

  private _saveToWorklist = false;

  get saveToWorklist(): boolean {
    return this._saveToWorklist;
  }

  set saveToWorklist(value: boolean) {
    this._saveToWorklist = value;
    value ? this.worklistNameControl.enable() : this.worklistNameControl.disable();
  }

  get worklistNameControl(): FormControl {
    return this.form.get('worklistName') as FormControl;
  }

  get templateUrl(): string {
    return `${ConfigService.settings.assetsBaseUrl}assets/templates/product-import-template.xlsx`;
  }

  startImportFlow(): void {
    if (this.form.invalid) return;
    this.cancel$ = new Subject<void>();

    this.flatfileService
      .uploadFile(productImportWorkbook)
      .pipe(
        untilDestroyed(this),
        filter((response) => !!response),
        tap(() => {
          this.loading = true;
          this.cdr.detectChanges();
        }),
        switchMap(({ jobId, workbookId }) => {
          const worklistName = this.saveToWorklist ? this.worklistNameControl.value : undefined;
          return this.flatfileService.importProducts(jobId, workbookId, worklistName);
        }),
        switchMap(({ userJobId }) => {
          return this.signalRService.userJobs$.pipe(
            filter((jobMsg) => jobMsg.id === userJobId),
            take(1),
            tap((jobMsg) => this.handleJobResult(jobMsg)),
          );
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
        finalize(() => {
          this.loading = false;
          this.cdr.detectChanges();
        }),
        takeUntil(this.cancel$),
      )
      .subscribe();
  }

  cancelUpload(): void {
    this.cancel$.next();
    this.activeModal.dismiss();
  }

  private handleJobResult({ status, result }): void {
    const parsedResult = this.parseJobResult(result);

    if (status === 'Completed') {
      this.activeModal.close(parsedResult);
      return;
    }

    if (['Failed', 'Canceled'].includes(status)) this.activeModal.dismiss();

    if (status === 'Failed') {
      const errorResult = parsedResult['errorResult'];
      const errorMessage = errorResult['errorMessage'];
      const errors = errorResult['errors'];

      errors
        ? this.modalService.open({ content: ImportErrorsModalComponent, inputs: { errorData: errors } })
        : this.toastr.displayServerErrors(errorMessage || result);
    }
  }

  private parseJobResult(jobResult: string): unknown {
    try {
      return JSON.parse(jobResult);
    } catch (error) {
      console.log(error);
    }
  }
}
